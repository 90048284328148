import React from "react"
import { graphql } from "gatsby"
import Layout from "../layouts/de"
import JobsPageBase from "../templates/jobs"

class JobsPage extends React.Component {
    render() {
        return (
            <Layout>
                <JobsPageBase data={this.props.data} locale="de" />
            </Layout>
        )
    }
}

export default JobsPage

export const query = graphql`
    query JobsDeQuery {
        jobtypes: allSanityJobtype(sort: {fields: displayOrder, order: ASC}) {
            edges {
                node {
                    id
                    title {
                        content: de
                    }
                    emptyText {
                        content: de
                    }
                    intro {
                        content: de
                    }
                    slug {
                        current
                    }
                }
            }
        }
        jobs: allSanityJob(
            sort: {order: ASC, fields: publishedAt},
            filter: {title: {de: {ne: null}}}
        ) {
            edges {
                node {
                    slug {
                        current
                    }
                    id
                    title {
                        content: de
                    }
                    jobtype {
                        id
                    }
                }
            }
        }
    }
`
